<template>
  <!--begin::Card-->
  <div class="card card-custom">
    <!--begin::Header-->
    <div class="card-header py-3">
      <div class="card-title align-items-start flex-column">
        <h3 class="card-label font-weight-bolder text-dark">Alterar a senha</h3>
        <span class="text-muted font-weight-bold font-size-sm mt-1"
          >Altere a senha da sua conta</span
        >
      </div>
      <div class="card-toolbar">
        <button
          type="submit"
          class="btn btn-success mr-2"
          @click="save()"
          ref="kt_save_changes"
        >
          Salvar
        </button>
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Form-->
    <form class="form" id="kt_password_change_form">
      <div class="card-body">
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-alert"
            >Senha Atual</label
          >
          <div class="col-lg-9 col-xl-6">
            <input
              type="password"
              class="form-control form-control-lg form-control-solid mb-2"
              v-model="current"
              placeholder="Senha Atual"
              name="current_password"
              ref="current_password"
              autocomplete="true"
            />
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-alert"
            >Nova Senha</label
          >
          <div class="col-lg-9 col-xl-6">
            <input
              type="password"
              class="form-control form-control-lg form-control-solid"
              v-model="password"
              placeholder="Nova Senha"
              name="new_password"
              ref="new_password"
              autocomplete="true"
            />
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-alert"
            >Confirmação de senha</label
          >
          <div class="col-lg-9 col-xl-6">
            <input
              type="password"
              class="form-control form-control-lg form-control-solid"
              v-model="confirm"
              placeholder="Confirmação de senha"
              name="verify_password"
              ref="verify_password"
              autocomplete="true"
            />
          </div>
        </div>
      </div>
    </form>
    <!--end::Form-->
  </div>
</template>

<script>
import KTUtil from "@/assets/js/components/util";

import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";

// FormValidation plugins
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";
import Swal from "sweetalert2";
import ApiService from "@/core/services/api.service";
import { mapGetters } from "vuex";
export default {
  name: "ChangePassword",
  data() {
    return {
      current: "",
      password: "",
      confirm: "",
      status: "",
      valid: true
    };
  },
  mounted() {
    const password_change_form = KTUtil.getById("kt_password_change_form");

    this.fv = formValidation(password_change_form, {
      fields: {
        current_password: {
          validators: {
            notEmpty: {
              message: "A senha atual é necessária"
            }
          }
        },
        new_password: {
          validators: {
            notEmpty: {
              message: "Nova senha é necessária"
            }
          }
        },
        verify_password: {
          validators: {
            notEmpty: {
              message: "É necessário confirmar a senha"
            },
            identical: {
              compare: function() {
                return password_change_form.querySelector(
                  '[name="new_password"]'
                ).value;
              },
              message: "A senha e sua confirmação não são iguais"
            }
          }
        }
      },
      plugins: {
        trigger: new Trigger(),
        bootstrap: new Bootstrap(),
        submitButton: new SubmitButton()
      }
    });
  },
  methods: {
    save() {
      this.fv.validate();

      this.fv.on("core.form.valid", () => {
        const submitButton = this.$refs["kt_save_changes"];

        submitButton.classList.add("spinner", "spinner-light", "spinner-right");
        submitButton.classList.remove(
          "spinner",
          "spinner-light",
          "spinner-right"
        );

        ApiService.post(`auth/changePassword/${this.currentUser._id}`, {
          current: this.current,
          password: this.password
        })
          .then(() => {
            Swal.fire({
              title: "Deu certo!",
              text: "Senha alterada com sucesso!",
              icon: "success",
              confirmButtonClass: "btn btn-secondary"
            });
          })
          .catch(() => {
            Swal.fire({
              title: "Ops!",
              text: "Senha atual não está válida!",
              icon: "error",
              confirmButtonClass: "btn btn-secondary"
            });
          });
      });

      this.fv.on("core.form.invalid", () => {
        Swal.fire({
          title: "Ops!",
          text: "Formulário não está válido!",
          icon: "error",
          confirmButtonClass: "btn btn-secondary"
        });
      });
    }
  },
  computed: {
    ...mapGetters(["currentUser"])
  }
};
</script>
