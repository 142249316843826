<template>
  <!--begin::Card-->
  <div class="card card-custom">
    <!--begin::Header-->
    <div class="card-header py-3">
      <div class="card-title align-items-start flex-column">
        <h3 class="card-label font-weight-bolder text-dark">
          Informações Pessoais
        </h3>
        <span class="text-muted font-weight-bold font-size-sm mt-1"
          >Matenha sempre seus dados atualizados.</span
        >
      </div>
      <div class="card-toolbar">
        <button
          type="reset"
          class="btn btn-success mr-2"
          @click="save()"
          ref="kt_save_changes"
        >
          Salvar
        </button>
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Form-->
    <form class="form">
      <!--begin::Body-->
      <div class="card-body">
        <div class="row">
          <label class="col-xl-3"></label>
          <div class="col-lg-9 col-xl-9">
            <h5 class="font-weight-bold mb-6">Informações Pessoais</h5>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right"
            >Nome</label
          >
          <div class="col-lg-9 col-xl-9">
            <input
              ref="name"
              class="form-control form-control-lg form-control-solid"
              type="text"
              v-model="currentUser.name"
            />
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right"
            >CPF ou CNPJ</label
          >
          <div class="col-lg-9 col-xl-9">
            <the-mask
              ref="cpf_cnpj"
              class="form-control form-control-lg form-control-solid"
              v-model="currentUser.cpf_cnpj"
              :mask="['###.###.###-##', '##.###.###/####-##']"
              :masked="true"
            />
          </div>
        </div>
        <template v-if="currentUser.address">
          <div class="row">
            <label class="col-xl-3"></label>
            <div class="col-lg-9 col-xl-9">
              <h5 class="font-weight-bold mb-6">Endereço</h5>
            </div>
          </div>

          <div class="form-group row">
            <label class="col-xl-3 col-lg-3 col-form-label text-right"
              >CEP</label
            >
            <div class="col-lg-3 col-xl-3">
              <the-mask
                placeholder="00000-000"
                ref="phone"
                class="form-control form-control-lg form-control-solid"
                v-model="currentUser.address.zip_code"
                :mask="['#####-###']"
                :masked="true"
              />
            </div>
            <label class="col-xl-3 col-lg-3 col-form-label text-right"
              >Número</label
            >
            <div class="col-lg-3 col-xl-3">
              <input
                ref="name"
                class="form-control form-control-lg form-control-solid"
                type="text"
                v-model="currentUser.address.number"
              />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-xl-3 col-lg-3 col-form-label text-right"
              >Logradouro</label
            >
            <div class="col-lg-9 col-xl-9">
              <input
                ref="name"
                class="form-control form-control-lg form-control-solid"
                type="text"
                v-model="currentUser.address.street"
              />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-xl-3 col-lg-3 col-form-label text-right"
              >Bairro</label
            >
            <div class="col-lg-9 col-xl-9">
              <input
                ref="name"
                class="form-control form-control-lg form-control-solid"
                type="text"
                v-model="currentUser.address.district"
              />
            </div>
          </div>

          <div class="form-group row">
            <label class="col-xl-3 col-lg-3 col-form-label text-right"
              >Cidade</label
            >
            <div class="col-lg-3 col-xl-3">
              <input
                ref="name"
                class="form-control form-control-lg form-control-solid"
                type="text"
                v-model="currentUser.address.city"
              />
            </div>
            <label class="col-xl-3 col-lg-3 col-form-label text-right"
              >Estado</label
            >
            <div class="col-lg-3 col-xl-3">
              <input
                ref="name"
                class="form-control form-control-lg form-control-solid"
                type="text"
                v-model="currentUser.address.state"
              />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-xl-3 col-lg-3 col-form-label text-right"
              >Complemento</label
            >
            <div class="col-lg-9 col-xl-9">
              <input
                ref="name"
                class="form-control form-control-lg form-control-solid"
                type="text"
                v-model="currentUser.address.complement"
              />
            </div>
          </div>
        </template>
        <div class="row">
          <label class="col-xl-3"></label>
          <div class="col-lg-9 col-xl-9">
            <h5 class="font-weight-bold mt-10 mb-6">Acesso</h5>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right"
            >Email</label
          >
          <div class="col-lg-9 col-xl-9">
            <div class="input-group input-group-lg input-group-solid">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="la la-at"></i>
                </span>
              </div>
              <input
                ref="email"
                type="text"
                class="form-control form-control-lg form-control-solid"
                placeholder="Email"
                v-model="currentUser.email"
              />
            </div>
          </div>
        </div>

        <div class="row">
          <label class="col-xl-3"></label>
          <div class="col-lg-9 col-xl-9">
            <h5 class="font-weight-bold mt-10 mb-6">Contato</h5>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right"
            >Telefone</label
          >
          <div class="col-lg-9 col-xl-9">
            <div class="input-group input-group-lg input-group-solid">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="la la-phone"></i>
                </span>
              </div>
              <the-mask
                placeholder="+55(11)98888-4444"
                ref="phone"
                class="form-control form-control-lg form-control-solid"
                v-model="currentUser.phone"
                :mask="['+55(##) ####-####', '+55(##) #####-####']"
                :masked="true"
              />
            </div>
            <span class="form-text text-muted"
              >Utilize o telefone com whatsapp para uma melhor
              comunicação.</span
            >
          </div>
        </div>
      </div>
      <!--end::Body-->
    </form>
    <!--end::Form-->
  </div>
</template>

<script>
import { ViaCEP } from "viacep";
import ApiService from "@/core/services/api.service";
import { UPDATE_USER } from "@/core/services/store/auth.module";
import Swal from "sweetalert2";
export default {
  name: "PersonalInformation",
  data() {
    return {
      currentUser: {},
      viaCep: new ViaCEP()
    };
  },
  mounted() {
    let current = this.$store.getters.currentUser;
    if (!current.address) {
      current.address = {};
    }
    this.$set(this, "currentUser", Object.assign({}, current));
  },
  methods: {
    save() {
      ApiService.put(`users/${this.currentUser._id}`, this.onSave)
        .then(() => {
          this.$store.dispatch(UPDATE_USER, this.currentUser);
          Swal.fire({
            title: "Deu certo!",
            text: "Informações pessoais alteradas com sucesso!",
            icon: "success",
            confirmButtonClass: "btn btn-secondary"
          });
        })
        .catch(() => {
          Swal.fire({
            title: "Ops!",
            text: "Tivemos algum problema para processar essa interação.",
            icon: "error",
            confirmButtonClass: "btn btn-danger"
          });
        });
    }
  },
  watch: {
    zipCode(val) {
      let zipCode = val.replace(/\D/g, "");
      this.currentUser.address = {
        zip_code: val,
        number: this.currentUser.address.number,
        complement: this.currentUser.address.complement
      };
      if (zipCode.length == 8) {
        (async () => {
          try {
            const data = await this.viaCep.cep(zipCode);
            this.currentUser.address = {
              zip_code: val,
              number: this.currentUser.address.number,
              complement: this.currentUser.address.complement,
              street: data.logradouro,
              district: data.bairro,
              city: data.localidade,
              state: data.uf
            };
          } catch (error) {
            console.log(error);
          }
        })();
      }

      // var config = {
      //   method: "post",
      //   url: "viacep.com.br/ws/01001000/json/",
      //   headers: {
      //     "Access-Control-Allow-Origin": "*",
      //     "Access-Control-Allow-Origin": "*",
      //     "Access-Control-Allow-Methods":
      //       "GET, POST, PATCH, PUT, DELETE, OPTIONS",
      //     "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token"
      //   }
      // };

      // axios(config)
      //   .then(function(response) {
      //     console.log(JSON.stringify(response.data));
      //   })
      //   .catch(function(error) {
      //     console.log(error);
      //   });

      // axios;
    }
  },
  computed: {
    zipCode() {
      if (!this.currentUser) return {};
      return this.currentUser?.address?.zip_code;
    },
    onSave() {
      const { name, phone, email, address, cpf_cnpj } = this.currentUser;
      return {
        name,
        phone,
        email,
        cpf_cnpj,
        address
      };
    }
  }
};
</script>
